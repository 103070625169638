.react-datepicker {  
    &__day {
      &--keyboard-selected,
      &--selected {
        background: #7a6138 !important;
        color: #c7c7c7 !important;
      }
    }
    &__time-list-item {
      color: #000000 !important;
      font-size: 13px !important;
  
      &--keyboard-selected,
      &--selected {
        background: #7a6138 !important;
        color: #c7c7c7 !important;
      }
    }
  }